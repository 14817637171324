import { render, staticRenderFns } from "./_DoseInput.vue?vue&type=template&id=fe2d813e&scoped=true"
import script from "./_DoseInput.vue?vue&type=script&lang=js"
export * from "./_DoseInput.vue?vue&type=script&lang=js"
import style0 from "./_DoseInput.vue?vue&type=style&index=0&id=fe2d813e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe2d813e",
  null
  
)

export default component.exports