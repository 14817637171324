<template>
  <div data-testid="prescription-add-dose-input">
    <v-card class="d-flex justify-end" flat>
      <v-card flat class="input-label">
        Prescribe new<br />
        <span class="text-lowercase">{{ $t(`time-point.${timepoint}`) }}</span>
      </v-card>

      <v-card flat class="ml-2">
        <v-text-field
          :maxlength="maxInputLength"
          inputmode="numeric"
          placeholder=" "
          outlined
          class="v-input__control--align-text-right small rounded-lg prescription-dose-input"
          :class="{ 'v-input--has-state warning--text': hasWarnings }"
          data-testid="dose-input-field"
          :value="value"
          @input="handleInput($event)"
          @change="$emit('auditEvent', $event)"
          dense
          :suffix="dosingDisplayUnit"
          hide-details
          :disabled="isLocked"
          :error="hasError"
          @keypress="allowNumbersOnly($event)"
          onPaste="return false"
        >
        </v-text-field>
      </v-card>
      <v-card v-if="isLocked" flat class="ml-2">
        <v-btn
          color="secondary"
          fab
          x-small
          dark
          depressed
          @click="openDialog"
          data-testid="btn-edit-dose"
          class="mt-1"
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
      </v-card>
    </v-card>
    <v-slide-y-reverse-transition :hide-on-leave="true" group>
      <small
        v-for="(warning, index) in doseWarnings"
        :key="`warning-${index}`"
        class="d-flex justify-end input-msg input-msg--warning mt-2"
        data-testid="titration-warning-small"
      >
        {{ warning }}
      </small>
    </v-slide-y-reverse-transition>

    <v-slide-y-reverse-transition :hide-on-leave="true" group>
      <small
        v-for="(error, index) in doseErrors"
        :key="`error-${index}`"
        class="d-flex justify-end input-msg input-msg--error mt-2"
        data-testid="titration-error-small"
      >
        {{ error }}
      </small>
    </v-slide-y-reverse-transition>

    <v-dialog v-model="dialog" max-width="458">
      <v-card>
        <v-card-text class="py-6">
          <v-row>
            <v-col class="py-0">
              <h5>Edit dose prescription</h5>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="black--text mt-2">
              Editing the dose prescription at this point means that<br />
              the
              <strong v-if="hasEffectiveDosingDay"
                >dose value except for the selected date will be cleared.
              </strong>
              <strong v-else>dose value will be cleared. </strong>
              <p class="mt-7 mb-4">Continue editing?</p>
            </v-col>
          </v-row>

          <v-row justify="end">
            <v-spacer />
            <v-col class="text-right pr-0 py-0">
              <v-btn rounded depressed @click="dialog = false">Cancel</v-btn>
            </v-col>
            <v-col sm="auto" class="py-0">
              <v-btn color="secondary" rounded depressed @click="gotoInputDose"
                >Continue</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import doseInputMixin from '../../mixins/dose-input/doseInputMixin'
import steps from '@/constants/prescriptionSteps'
import isNumeric from '@/utils/isNumeric'

export default {
  name: 'DoseInput',
  mixins: [doseInputMixin],
  props: {
    doseOk: { type: Boolean, default: null },
    hasEffectiveDosingDay: { type: Boolean, required: false, default: false },
    step: { type: Number, required: true },
    timepoint: { type: String, required: true },
    dosingDisplayUnit: { type: String, required: true },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    isLocked() {
      return this.step === steps.INPUT_REASON
    },
    doseErrors() {
      return this.validateInput
    },
    hasError() {
      return this.doseErrors.length > 0
    },
  },
  methods: {
    handleInput($event) {
      const targetValue = $event

      this.$emit('input', targetValue)

      if (targetValue === '') {
        this.$emit('update:doseOk', false)
      } else if (targetValue && !isNumeric(targetValue)) {
        this.$emit('update:doseOk', false)
      } else {
        const isDoseOk = this.validationErrors(targetValue).length === 0
        this.$emit('update:doseOk', isDoseOk)
      }
    },
    gotoInputDose() {
      this.$emit('reset', '')
      this.dialog = false
    },
    openDialog() {
      this.dialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
.input-label {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.prescription-dose-input {
  width: 106px;
}

.v-input--is-disabled {
  color: $nn-granite-gray;
  font-weight: bold;
  background-color: #f1f2f3;
}
</style>
