<template>
  <div>
    <v-row>
      <v-col class="text-right pb-2">
        <small class="spacing-2 highlighted">
          The prescribed dose is not as expected per titration guideline
        </small>
      </v-col>
    </v-row>

    <v-row data-testid="dose-reason" class="dose-reason-row">
      <v-col :class="[{ 'pb-2': reasonType !== 2 }, 'text-right py-0']">
        <v-row>
          <v-col class="">
            <h6 class="mr-4 mt-2">Please choose a reason for deviation:</h6>

            <v-radio-group
              v-model="reasonType"
              row
              :hide-details="true"
              @change="changeReasonType"
              class="float-right mt-1"
            >
              <v-radio
                v-for="(n, i) in reasonTypeOptions"
                :key="i"
                :label="n.text"
                :value="n.value"
                :class="[
                  { selected: n.value === reasonType },
                  'boxed-radio-btn',
                ]"
              />
            </v-radio-group>
          </v-col>
        </v-row>

        <v-slide-y-reverse-transition :hide-on-leave="true">
          <v-row v-if="reasonType === 2">
            <v-col class="py-0 text-right">
              <v-textarea
                counter
                outlined
                class="mt-2 reason-text rounded-lg spacing-2"
                rows="4"
                :auto-grow="true"
                ref="inputtextarea"
                :value="value"
                @input="handleInput"
                @change="$emit('auditReason', $event)"
                :rules="reasonProvidedRules"
                :maxlength="reasonProvidedMaxLength"
                background-color="white"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-slide-y-reverse-transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import reasonOtherValidationMixin from '@/components/mixins/reasonOtherValidationMixin'
import deviationReason from '@/constants/deviationReason'

export default {
  name: 'DoseReason',
  mixins: [reasonOtherValidationMixin],
  props: {
    value: { type: String, required: true, default: '' },
    reasonOk: { type: Boolean, default: false },
    reason: { type: String, default: '' },
    deviationReasons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      reasonType: null,
      touched: false,
      reasonTextRules: [v => !!v || 'The reason cannot be empty.'],
    }
  },
  computed: {
    reasonTypeOptions() {
      let options = []
      if (this.deviationReasons.includes(deviationReason.HYPO)) {
        options.push({ text: 'Hypoglycaemia', value: 0 })
      }
      if (
        this.deviationReasons.includes(
          deviationReason.GASTROINTESTINAL_ADVERSE_EVENT
        )
      ) {
        options.push({ text: 'Gastrointestinal adverse event', value: 1 })
      }
      if (this.deviationReasons.includes(deviationReason.OTHER)) {
        options.push({ text: 'Other', value: 2 })
      }

      return options
    },
  },
  methods: {
    handleInput($event) {
      this.$emit('input', $event)
      this.$emit('update:reasonOk', !this.hasReasonProvidedErrors($event))
    },
    changeReasonType(arg) {
      if (arg === null) {
        this.$emit('input', '')
        this.$emit('update:reasonOk', false)
      } else if (arg === 0) {
        this.$emit('update:reasonOk', true)
        this.$emit('update:reason', deviationReason.HYPO)
        this.$emit('auditType', 'hypoglycemia')
      } else if (arg === 1) {
        this.$emit('update:reasonOk', true)
        this.$emit(
          'update:reason',
          deviationReason.GASTROINTESTINAL_ADVERSE_EVENT
        )
        this.$emit('auditType', 'gastrointestinalAdverseEvent')
      } else {
        this.$emit('update:reasonOk', false)
        this.$emit('update:reason', deviationReason.OTHER)
        this.$emit('auditType', 'other')
        this.$nextTick(() => {
          this.$refs.inputtextarea.$el.querySelector('textarea').focus()
          this.$emit('scrollToBottom')
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.boxed-radio-btn {
  border: 1px solid $nn-granite-gray;
  box-sizing: border-box;
  padding: 11px;
  border-radius: 6px;
  width: 230px;

  &.selected {
    background-color: $nn-LB_T95;
    border-color: $nn-sea-blue;
  }
}

.reason-text {
  width: 476px;
  float: right;
  margin-right: 16px;
}

.highlighted {
  color: $nn-golden-sun;
}

.dose-reason-row {
  background-color: $nn-SG_T95;
  margin-left: -28px;
  margin-right: -28px;
}
</style>
