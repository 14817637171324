import Vue from 'vue'
import format from 'date-fns/format'
import { enGB } from 'date-fns/locale'

import steps from '@/constants/prescriptionSteps'
import loadingState from '@/constants/loadingState'
import underlyingDataContext from '@/constants/underlyingDataContext'
import prescriptionService from '@/services/prescription-service'
import NotificationType from '@/store/modules/notification/NotificationType'
import parseDateTimeWithoutTimeZone from '@/utils/date/parseDateTimeWithoutTimeZone'

export default {
  data() {
    return {
      currentStep: steps.INPUT_DOSE,
      dose: '',
      doseOk: false,
      doseReason: null,
      doseReasonText: '',
      doseReasonOk: false,
      effectiveDateRules: [v => !!v || 'Please select a date'],
      selectedEffectiveDosingDay: null,
      selectedEffectiveDosingDate: null,
      underlyingDataContext,
      deviationReasons: [],
    }
  },
  computed: {
    isStepInputReason() {
      return this.currentStep === steps.INPUT_REASON
    },
    hasEffectiveDosingDay() {
      return !!this.recommendation.treatment?.effectiveDosingDates?.length
    },
    isInputValid() {
      if (this.hasEffectiveDosingDay && !this.selectedEffectiveDosingDay) {
        return false
      }

      if (this.currentStep === steps.INPUT_REASON) {
        return this.doseReasonOk
      }

      return this.doseOk
    },
    effectiveDateOptions() {
      if (!this.hasEffectiveDosingDay) return []

      return this.recommendation.treatment.effectiveDosingDates.map(
        (item, index) => {
          const startDate = parseDateTimeWithoutTimeZone(item.start.time)
          const dateFormatted = format(startDate, 'dd MMM yyyy', {
            locale: enGB,
          })

          return {
            value: index + 1,
            text: dateFormatted,
          }
        }
      )
    },
    lastPrescribedDoseValue() {
      return this.recommendation.lastPrescribedDose?.doseValue
    },
    lastPrescribedDosingDisplayUnit() {
      return this.recommendation.lastPrescribedDose?.dosingDisplayUnit
    },
  },
  methods: {
    addAuditEntry(fieldName, value) {
      this.auditLog.log(fieldName, value)
    },
    addEffectiveDosingDayAuditEntry(value) {
      if (this.hasEffectiveDosingDay) {
        const item = this.effectiveDateOptions.find(x => x.value === value)

        if (item) {
          this.addAuditEntry('PRESCRIBE_DOSE:EFFECTIVE_DOSING_DAY', item.text)
        }
      }
    },
    isDirty() {
      const isDoseDirty = this.dose !== ''
      const isEffectiveDateDirty = this.selectedEffectiveDosingDay != null
      return isDoseDirty || isEffectiveDateDirty
    },
    resetForm() {
      this.resetInput()
      this.selectedEffectiveDosingDay = null
      if (this.$refs.effectiveDosingDaySelector) {
        this.$refs.effectiveDosingDaySelector.reset()
      }
    },
    resetInput() {
      this.dose = ''
      this.doseOk = false
      this.doseReason = null
      this.doseReasonText = ''
      this.doseReasonOk = false
      this.currentStep = steps.INPUT_DOSE
      this.state = loadingState.INITIAL
      this.auditLog.logReset('PRESCRIBE_DOSE:REASON_FOR_DEVIATION_TEXT')
      this.auditLog.logReset('PRESCRIBE_DOSE:REASON_FOR_DEVIATION_TYPE')
      this.auditLog.logReset(
        this.doseValueAuditKey(this.recommendation.treatment.timepoint)
      )
    },
    scrollToBottom() {
      this.$refs.bottomscreen?.scrollIntoView?.({
        behavior: 'smooth',
      })
    },
    // TODO: don't call it recommendation, call it basal_once_weekly or basal_once_daily
    validateDoseInput() {
      const doseFloat = parseFloat(this.dose)

      this.state = loadingState.LOADING
      prescriptionService
        .checkDoseValue(
          this.sessionResponse.patientNumber,
          this.sessionResponse.sessionId,
          {
            timepoint: this.recommendation.treatment.timepoint,
            value: doseFloat,
          }
        )
        .then(result => {
          const validation = result.doses.find(
            s => s.timepoint === this.recommendation.treatment.timepoint
          )

          if (validation.isReasonForDeviationRequired) {
            this.doseReasonOk = false
            this.deviationReasons = validation.deviationReasons
            this.currentStep = steps.INPUT_REASON
            this.state = loadingState.LOAD_SUCCEEDED
          } else {
            this.createPrescription(this.getDoses(false))
          }
        })
        .catch(error => {
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
          this.state = loadingState.LOAD_ERRORED

          this.spawnNotification({
            type: NotificationType.Error,
            title: 'Data could not be sent, please try again.',
          })
        })
    },
    validatePrescription() {
      switch (this.currentStep) {
        case steps.NOT_ACTIVE:
          break
        case steps.INPUT_DOSE:
          this.validateDoseInput()
          break
        case steps.INPUT_REASON:
          this.createPrescription(this.getDoses(true))
          break
        default:
          throw new Error(
            `InvalidEnumArgumentException: Unexpected value in currentStep: ${this.currentStep}`
          )
      }
    },
  },
}
